$(function() {
  $('.navToggle').click(function() {
      $(this).toggleClass('active');

      if ($(this).hasClass('active')) {
          $('.header_menu').addClass('active');
      } else {
          $('.header_menu').removeClass('active');
      }
  });
});